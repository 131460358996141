import SubscriptionModal from '@/baseline/subscription';
import UpgradeIcon from '@/baseline/subscription/upgradeIcon';
import useTierPermission from '@/baseline/subscription/useTierPermission';
import type { ActionProps } from '@/components/actions';
import PageLinkComponent from '@/components/page/linkComponent';
import { mutateGraphQL, queryGraphQL } from '@/data';
import { EstimateRead, EstimateWrite } from '@/data/commerce/estimate.graphql';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import SchedulePaymentsModal from '@/modals/schedulePaymentsModal';
import TextMessage from '@/modals/textMessage';
import { approveToAction } from '@/pages/dashboard/commerce/estimates/actions/estimateUtils';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import { CommerceType, MutationEstimateWriteArgs, Order, QueryEstimateReadArgs } from '@/types/schema';
import {
	CancelRounded as CancelRoundedIcon,
	ChangeCircle as CreateOrderIcon,
	ChangeCircle as CreateInvoiceIcon,
	ConfirmationNumber as ConfirmationNumberIcon,
	ContentCopy as ContentCopyIcon,
	CopyAll as CopyAllIcon,
	Delete as DeleteIcon,
	Edit as EditIcon,
	Email as EmailIcon,
	EventRepeat as EventRepeatIcon,
	FindInPage as FindInPageIcon,
	LocalOffer as LocalOfferIcon,
	MoreVert as MoreVertIcon,
	Share as ShareIcon,
	Sms as SmsIcon,
	ThumbUp as ApprovedIcon,
	TransformRounded as TransformRoundedIcon,
} from '@mui/icons-material';
import { type Theme, useMediaQuery } from '@mui/material';
import axios from 'axios';
import { addDays } from 'date-fns';
import { isEmpty, omit, pick, toLower, toUpper, upperFirst } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SignAndTagModal from '../../components/signAndTagModal';
import { stayOpen } from '../../components/tableHelpers';
import { commercePropertiesToOmit, lineItemPropertiesToOmit } from '../../invoices/actions/invoiceUtils';
import { updateStock } from '../../orders/utils';
import ApproveAndInvoiceOrMoveModal from '../approve&InvoiceModal';
import ApproveAndWorkOrderModal from '../approveAndWorkOrderModal';
import { usePartialEstimateTableActions } from './drawerActions';

const estimateTypes = [ 'bid', 'estimate', 'quote', 'proposal', 'work_order' ];

export default function useEstimateActions( estimate: Order, single?: boolean ) {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { showModal } = useModal();
	const { staff, user } = useUserInfo();
	const editable = usePermissions( permissions.estimates.write );
	const router = useRouter();
	const estimateActions = usePartialEstimateTableActions();
	const { isCloverDevice } = useGetDeviceInfo();
	const confirmDialog = useConfirmDialog();
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const subscriptionIsValid = useTierPermission( 'INVOICE_TEXT' );
	
	const moreActions = [ {
		name   : t( 'common:ticket' ),
		details: t( 'common:recommend-printer' ),
		icon   : <ConfirmationNumberIcon/>,
		props  : {
			component: PageLinkComponent,
			href     : `${process.env.NEXT_PUBLIC_SERVER_URL}/api/preview/print/${estimate.id}/ticket`,
			target   : '_blank',
		},
	}, estimate.status === 'DRAFT' && !estimate.standing && {
		name   : t( 'common:mark-sent' ),
		details: t( 'commerce:assign-estimate-without-sending', { estimateType: estimate.type } ),
		icon   : <EmailIcon/>,
		onClick: async () => await mutateGraphQL<MutationEstimateWriteArgs>( {
			mutation : EstimateWrite,
			variables: {
				id    : estimate.id,
				method: 'Marked Sent',
				input : { sent: true },
			},
		} ),
	}, editable && {
		name   : estimate.status === 'CANCELLED' ? t( 'common:uncancel' ) : t( 'common:cancel' ),
		icon   : <CancelRoundedIcon/>,
		onClick: () => mutateGraphQL<MutationEstimateWriteArgs>( {
			mutation : EstimateWrite,
			variables: {
				id    : estimate.id,
				method: estimate.status === 'CANCELLED' ? 'Marked UnCancel' : 'Marked Cancel',
				input : { cancelled: estimate.status !== 'CANCELLED' },
			},
		} ),
	}, editable && {
		name   : t( 'common:delete' ),
		icon   : <DeleteIcon/>,
		onClick: async () => {
			const value = await confirmDialog( {
				title  : t( 'common:delete' ),
				message: t( 'common:delete-confirmation' ),
			} );
			if ( !value ) return;
			await mutateGraphQL<MutationEstimateWriteArgs>( {
				mutation : EstimateWrite,
				variables: {
					id    : estimate.id,
					method: 'Deleted Estimate',
					input : {
						deletedAt: new Date(),
						number   : estimate.number,
					},
				},
			} );
			const loweredType = upperFirst( toLower( estimate.type ) );
			enqueueSnackbar( `${loweredType} ${t( 'common:delete-success' )}` );
			await router.push( '/dashboard/commerce/estimates' );
		},
	} ];
	
	const ConvertActions = estimateTypes.filter( ( type ) => type !== toLower( estimate.type ) ).map( ( type ) => ( {
		name   : `${t( 'commerce:convert-to' )} ${t( `common:${type}` )}`,
		details: t( `commerce:turn-${type}` ),
		icon   : <CreateOrderIcon/>,
		onClick: async () => {
			const value = await confirmDialog( {
				title  : t( `commerce:confirm-${type}-invoice` ),
				message: t( `commerce:success-${type}` ),
			} );
			if ( !value ) return;
			try {
				await mutateGraphQL<MutationEstimateWriteArgs>( {
					mutation : EstimateWrite,
					variables: {
						id          : estimate.id,
						customNumber: Boolean( staff.company.metadata?.customNumber ),
						input       : {
							type : toUpper( type ) as CommerceType,
							staff: staff.id,
						},
					},
				} );
				
				await router.push( `/dashboard/commerce/estimates/${estimate.id}/edit` );
			} catch ( e ) {
				throw e;
			}
		},
	} ) );
	
	return [
		editable && {
			name : t( 'common:edit' ),
			icon : <EditIcon/>,
			props: {
				component: PageLinkComponent,
				href     : `/dashboard/commerce/estimates/${estimate.id}/edit`,
			},
		},
		!single && {
			name : t( 'common:view' ),
			icon : <FindInPageIcon/>,
			props: {
				component: PageLinkComponent,
				href     : `/dashboard/commerce/estimates/${estimate.id}`,
			},
		}, editable && navigator.share && {
			name   : t( 'common:share' ),
			icon   : <ShareIcon/>,
			onClick: () => navigator.share( { url: `${process.env.NEXT_PUBLIC_SITE_URL}/p/${estimate.id}/estimate` } ),
		},
		...estimateActions( estimate ).slice( 1 ),
		estimate.turnEstimateInto === 'WORK_ORDER' && {
			name   : t( 'common:approve-work-order' ),
			icon   : <CreateInvoiceIcon/>,
			onClick: () => showModal( ApproveAndWorkOrderModal, { maxWidth: 'sm' }, {
				onSave: async () => {
					await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/tempApproveEstimate`, {
						id       : estimate.id,
						companyId: estimate.company?.id,
					} );
				},
			} ),
		}, {
			name   : estimate.status === 'COMPLETED' ? t( 'common:create-invoice' ) : estimate.status === 'INVOICED'
				? t( 'common:recreate-invoice' )
				: t( 'common:approve-invoice' ),
			icon   : <CreateInvoiceIcon/>,
			onClick: () => showModal( ApproveAndInvoiceOrMoveModal, { maxWidth: 'sm' }, {
				convertType: 'INVOICE',
				onSave     : async ( option, keepNumber, customCreatedAt ) => {
					await approveToAction( estimate, staff, option, keepNumber, customCreatedAt, 'INVOICE', router, enqueueSnackbar, t );
				},
			} ),
		}, {
			name   : estimate.status === 'COMPLETED' ? t( 'common:create-order' ) : estimate.status === 'ORDERED'
				? t( 'common:recreate-order' )
				: t( 'common:approve-order' ),
			icon   : <CreateInvoiceIcon/>,
			onClick: () => showModal( ApproveAndInvoiceOrMoveModal, { maxWidth: 'sm' }, {
				convertType: 'ORDER',
				onSave     : async ( option, keepNumber, customCreatedAt ) => {
					await approveToAction( estimate, staff, option, keepNumber, customCreatedAt, 'ORDER', router, enqueueSnackbar, t );
				},
			} ),
		}, editable && estimate.status !== 'CANCELLED' && !estimate.standing && {
			name   : t( 'common:schedule-payment' ),
			icon   : <EventRepeatIcon/>,
			onClick: () => showModal( SchedulePaymentsModal, {
				onClose      : ( event, reason ) => stayOpen( event, reason ),
				maxWidth     : 'sm',
				fullPageModal: true,
			}, { id: estimate.id } ),
		}, estimate.status !== 'COMPLETED' && estimate.status !== 'INVOICED' && estimate.type !== 'INVOICE' && {
			name       : t( 'common:mark-expired' ),
			icon       : <CreateInvoiceIcon/>,
			buttonProps: { variant: 'outlined', color: 'error' },
			onClick    : async () => {
				const value = await confirmDialog( {
					title  : t( 'common:expire-estimate' ),
					message: t( 'common:expire-estimate-confirmation' ),
				} );
				if ( !value ) return;
				await mutateGraphQL<MutationEstimateWriteArgs>( {
					mutation : EstimateWrite,
					variables: {
						id    : estimate.id,
						method: 'Made Expired',
						input : { expired: true, sent: true },
					},
				} );
			},
		}, {
			name   : estimate.status === 'DRAFT' ? t( 'common:send-text' ) : t( 'common:resend-text' ),
			icon   : <SmsIcon/>,
			endNode: !subscriptionIsValid && <UpgradeIcon permission='INVOICE_TEXT'/>,
			onClick: () => {
				if ( !subscriptionIsValid ) return showModal( SubscriptionModal, { variant: 'fullPageDialog' } );
				return showModal( TextMessage, { maxWidth: 'xs' }, {
					order   : estimate,
					onSubmit: async () => {
						await mutateGraphQL<MutationEstimateWriteArgs>( {
							mutation : EstimateWrite,
							variables: {
								id    : estimate.id,
								method: 'Sent via Text',
								input : {
									sent    : true,
									metadata: {
										...estimate.metadata,
										sentDates: [ ...!isEmpty( estimate.metadata?.sentDates )
											? [ ...estimate.metadata.sentDates, new Date() ]
											: [ new Date() ] ],
									},
								},
							},
						} );
						await updateStock( estimate, staff.company, 'manualSendStock', enqueueSnackbar );
					},
				} );
			},
		}, estimate.status !== 'COMPLETED' && estimate.status !== 'INVOICED' && estimate.type !== 'INVOICE' && {
			name       : t( 'common:mark-expired' ),
			icon       : <CreateInvoiceIcon/>,
			buttonProps: { variant: 'outlined', color: 'error' },
			onClick    : async () => {
				const value = await confirmDialog( {
					title  : t( 'common:expire-estimate' ),
					message: t( 'common:expire-estimate-confirmation' ),
				} );
				if ( !value ) return;
				await mutateGraphQL<MutationEstimateWriteArgs>( {
					mutation : EstimateWrite,
					variables: {
						id    : estimate.id,
						method: 'Made Expired',
						input : { expired: true, sent: true },
					},
				} );
			},
		}, editable && !estimate.standing && {
			name   : t( 'common:sign-tag' ),
			icon   : <LocalOfferIcon/>,
			onClick: () => showModal( () => <SignAndTagModal data={estimate}/>, { maxWidth: 'xs' } ),
		}, editable && estimate.status !== 'COMPLETED' && estimate.status !== 'INVOICED' && {
			name   : t( 'common:approve-only' ),
			details: t( 'common:approve-only-detail' ),
			icon   : <ApprovedIcon/>,
			onClick: async () => {
				const value = await confirmDialog( {
					title  : t( 'common:approve-estimate' ),
					message: t( 'common:approve-estimate-confirmation' ),
				} );
				if ( !value ) return;
				await mutateGraphQL<MutationEstimateWriteArgs>( {
					mutation : EstimateWrite,
					variables: {
						id    : estimate.id,
						method: 'Approved Only',
						input : { completed: true, sent: true },
					},
				} );
			},
		}, {
			name   : t( 'common:copy-link' ),
			icon   : <ContentCopyIcon/>,
			onClick: async () => {
				await navigator.clipboard.writeText( `${process.env.NEXT_PUBLIC_SITE_URL}/p/${estimate.id}/estimate` );
				enqueueSnackbar( `${t( 'common:link-copy-success' )}`, { variant: 'success' } );
			},
		}, !isCloverDevice && {
			name : t( 'common:client-view' ),
			icon : <FindInPageIcon/>,
			props: {
				component: PageLinkComponent,
				href     : `/p/${estimate.id}/estimate`,
				target   : '_blank',
			},
		}, editable && {
			name   : t( 'common:make-copy' ),
			icon   : <CopyAllIcon/>,
			onClick: async () => {
				try {
					
					const value = await confirmDialog( {
						title  : t( 'common:make-copy' ),
						message: t( 'common:make-copy-confirmation' ),
					} );
					if ( !value ) return;
					const { estimateRead } = await queryGraphQL<QueryEstimateReadArgs>( {
						query    : EstimateRead,
						variables: { id: estimate.id },
					} );
					
					const lineItems = estimateRead.lineItems;
					const agreements = estimateRead.agreements;
					const prices = estimateRead.prices;
					
					const { estimateWrite } = await mutateGraphQL<MutationEstimateWriteArgs>( {
						mutation : EstimateWrite,
						variables: {
							id          : null,
							method      : 'Copied',
							customNumber: Boolean( staff.company.metadata?.customNumber ),
							input       : {
								...pick( estimateRead, [
									'type',
									'notes',
									'po',
									'terms',
									'standing',
									'standingDue',
									'standingData',
									'duePeriod',
									'serviceType',
									'attachments',
									'dateSent',
									'taxPercent' ] ),
								standingDate   : new Date(),
								dueDate        : addDays( new Date(), estimateRead.company?.metadata?.expiresDate || estimateRead.company?.metadata?.dueDate || estimateRead.company?.metadata?.serviceDateDue || 0 ),
								serviceDate    : new Date(),
								staff          : estimateRead.staff?.id,
								client         : estimateRead.client?.id || null,
								companyLocation: estimateRead.companyLocation?.id || null,
								shippingAddress: estimateRead.shippingAddress?.id || null,
								clientAddress  : estimateRead.clientAddress?.id || null,
								metadata       : {
									...omit( estimateRead.metadata, commercePropertiesToOmit ),
									signatureLine: staff.company.metadata?.signatureLine,
									
								},
								agreements     : agreements?.map( ( agreement ) => ( {
									...pick( agreement, [
										'title',
										'body',
										'requireSignature',
										'expiration',
									] ),
									company: estimate.company.id,
								} ) ),
								lineItems      : lineItems?.map( ( lineItem ) => ( {
									...pick( lineItem, [
										'name',
										'price',
										'originalPrice',
										'cashDiscount',
										'image',
										'unit',
										'quantity',
										'tax',
										'sequence',
										'externalId',
										'orderTax',
									] ),
									metadata      : omit( lineItem.metadata, lineItemPropertiesToOmit ),
									description   : `${lineItem.description || ''}`,
									modifierGroups: lineItem.modifierGroups?.map( ( { id } ) => id ),
									prices        : lineItem.prices?.map( ( price ) =>
										pick( price, [
											'name',
											'isPercent',
											'value',
											'quantity',
											'metadata',
											'externalId',
										] ) ),
									uom           : lineItem.uom?.id || null,
									item          : lineItem.item?.id || null,
									category      : lineItem.category?.id || null,
								} ) ),
								prices         : !isEmpty( prices )
									? prices.filter( ( { name } ) => name !== 'Card Processing Fee' && name !== 'Cash Discount' && name !== 'Credit' )
										.map( ( price ) =>
											pick( price, [
												'name',
												'isPercent',
												'value',
												'quantity',
												'metadata',
											] ) )
									: [],
							},
						},
					} );
					
					await router.push( `/dashboard/commerce/estimates/${estimateWrite.id}/edit` );
				} catch ( e ) {
					console.log( e );
					throw e;
				}
			},
		},
		...editable && estimate.status !== 'PAID' && estimate.status !== 'PARTIALLY_PAID'
			? isMobile
				? ConvertActions
				: [ {
					name       : t( 'commerce:convert-to' ),
					icon       : <TransformRoundedIcon/>,
					nestedItems: ConvertActions.filter( Boolean ),
				} ]
			: [],
		...isMobile
			? moreActions
			: [ {
				name       : t( 'common:more-action' ),
				icon       : <MoreVertIcon/>,
				nestedItems: moreActions.filter( Boolean ),
			} ],
	
	] as ActionProps[];
}
