import Actions from '@/components/actions';
import { mutateGraphQL } from '@/data';
import { EstimatesWrite } from '@/data/commerce/estimate.graphql';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import { InvoicesReminderEmailModal } from '@/modals/email';
import MergeOptions from '@/modals/mergeOptionsModal';
import SelectStaffModal from '@/modals/selectStaff';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import type { MutationEstimatesWriteArgs, Order } from '@/types/schema';
import {
	CallMerge as CallMergeIcon,
	CallSplit as CallSplitIcon,
	CancelRounded as CancelRoundedIcon,
	Delete as DeleteIcon,
	Email as EmailIcon,
	PeopleAlt as PeopleAltIcon,
} from '@mui/icons-material';
import { type Theme, useMediaQuery } from '@mui/material';
import { isEmpty, toLower } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { stayOpen } from '../../components/tableHelpers';

export default function EstimatesActions( {
	estimates,
	clearRows,
	selectedEstimates,
}: {
	estimates: string[],
	clearRows: () => void,
	selectedEstimates: Order[]
} ) {
	const { user } = useUserInfo();
	const confirmDialog = useConfirmDialog();
	const { showModal } = useModal();
	const { isCloverDevice } = useGetDeviceInfo();
	const { t } = useTranslation();
	
	const editable = usePermissions( permissions.estimates.write );
	const creatable = usePermissions( permissions.estimates.write );
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const isIpad = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'lg' ) );
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	
	const hasPartiallyPaid = Boolean( selectedEstimates.find( ( estimate ) => estimate.status === 'PARTIALLY_PAID' ) );
	const selectedClientWithEmail = selectedEstimates.find( ( invoice ) => invoice.client?.email )?.client;
	
	const hasSameClientEmail = selectedClientWithEmail?.email && !isEmpty( selectedEstimates )
		? selectedEstimates.every( ( estimate ) => toLower( estimate.client?.email || '' ) === toLower( selectedClientWithEmail.email ) )
		: false;
	
	return (
		<Actions
			separated
			max={isMobile ? 1 : isCloverDevice || isIpad ? 4 : 0}
			items={[
				creatable && estimates.length > 1 && {
					name       : t( 'commerce:merge' ),
					details    : hasPartiallyPaid
						? 'Partially paid estimates cannot be merged.'
						: t( 'commerce:merge-multiple-estimates-into-one' ),
					icon       : <CallMergeIcon/>,
					buttonProps: { disabled: hasPartiallyPaid, color: 'success' },
					onClick    : () => {
						showModal( MergeOptions, { maxWidth: 'sm' }, {
							type             : 'Estimate',
							clearRows        : clearRows,
							commerces        : estimates,
							selectedCommerces: selectedEstimates,
						} );
					},
				}, editable && {
					name   : t( 'commerce:un-merge' ),
					icon   : <CallSplitIcon/>,
					onClick: async () => {
						await mutateGraphQL<MutationEstimatesWriteArgs>( {
							mutation : EstimatesWrite,
							variables: {
								ids    : estimates,
								options: { limit: estimates.length },
								input  : { merged: null },
							},
						} );
					},
				}, {
					name    : 'Email',
					icon    : <EmailIcon/>,
					disabled: !hasSameClientEmail,
					details : !hasSameClientEmail
						? 'You must select estimates for the same client to send them a reminder.'
						: '',
					onClick : async () => {
						showModal( InvoicesReminderEmailModal, {
							onClose: ( event, reason ) => stayOpen( event, reason ),
						}, { invoices: selectedEstimates, subject: 'Please view all your estimates' } );
					},
				}, ( isOwner || isAdmin ) && {
					name   : t( 'commerce:edit-placed-by' ),
					icon   : <PeopleAltIcon/>,
					onClick: () => showModal( SelectStaffModal, { maxWidth: 'sm' }, {
						type        : 'estimate',
						clearRows   : clearRows,
						selectedRows: estimates,
					} ),
					
				}, editable && {
					name       : t( 'common:cancel' ),
					icon       : <CancelRoundedIcon/>,
					buttonProps: { color: 'warning' },
					onClick    : async () => {
						await mutateGraphQL<MutationEstimatesWriteArgs>( {
							mutation : EstimatesWrite,
							variables: {
								ids    : estimates,
								options: { limit: estimates.length },
								input  : { cancelled: true },
							},
						} );
						clearRows();
					},
				}, editable && {
					name   : t( 'common:uncancel' ),
					icon   : <CancelRoundedIcon/>,
					onClick: async () => {
						await mutateGraphQL<MutationEstimatesWriteArgs>( {
							mutation : EstimatesWrite,
							variables: {
								ids    : estimates,
								options: { limit: estimates.length },
								input  : { cancelled: false },
							},
						} );
						clearRows();
					},
				}, editable && {
					name       : t( 'common:delete' ),
					icon       : <DeleteIcon/>,
					buttonProps: { color: 'error' },
					onClick    : async () => {
						const value = await confirmDialog( {
							title  : t( 'common:delete' ),
							message: t( 'common:delete-confirmation' ),
						} );
						if ( !value ) return;
						await mutateGraphQL<MutationEstimatesWriteArgs>( {
							mutation : EstimatesWrite,
							variables: {
								ids    : estimates,
								options: { limit: estimates.length },
								input  : { deletedAt: new Date() },
							},
						} );
						clearRows();
					},
				},
			]}
		/>
	);
}
