import type { ActionProps } from '@/components/actions';
import { mutateGraphQL } from '@/data';
import { EstimateWrite } from '@/data/commerce/estimate.graphql';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import type { MutationEstimateWriteArgs, Order } from '@/types/schema';
import { SettingsBackupRestore as SettingsBackupRestoreIcon } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export default function useDeletedEstimateActions( estimate: Order ) {
	const editable = usePermissions( permissions.estimates.write );
	const confirmDialog = useConfirmDialog();
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	return [
		editable && {
			name   : t( 'common:restore' ),
			icon   : <SettingsBackupRestoreIcon/>,
			onClick: async () => {
				const value = await confirmDialog( {
					title  : t( 'common:recover' ),
					message: t( 'common:are-you-sure-you-want-to-recover' ),
				} );
				if ( !value ) return;
				await mutateGraphQL<MutationEstimateWriteArgs>( {
					mutation : EstimateWrite,
					variables: {
						id    : estimate.id,
						method: 'Recovered',
						input : { deletedAt: null },
					},
				} );
				await queryClient.invalidateQueries( [ 'order' ] );
				
			},
		},
	] as ActionProps[];
}
